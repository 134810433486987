// Fonts
// Variables
// Bootstrap
$brand1: rgb(41,65,83 );
$brand2: rgb(131,142,156);
$brand3: rgb(214,177,58);
$border-radius:1em ;

@mixin gold-border {
  border: 2px solid $brand3;
  background-color: #fff;
  border-radius: 10px;
}

html {
  box-sizing: border-box;
  // font-family: sans-serif;
  // line-height: 1.15;
  // -webkit-text-size-adjust: 100%;
  // -ms-text-size-adjust: 100%;
  // -ms-overflow-style: scrollbar;
  // -webkit-tap-highlight-color: transparent;
}

*,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    font: 18px/1.5 'Philosopher', sans-serif;
    color: $brand2;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    color: $brand1;
    text-transform: uppercase;
  }

  h1 {
    font-size: 27px;
  }

  body > header {
    margin:0 2em;
    min-height: 120px;
    display: flex;
    flex-flow: wrap;
    align-items: flex-end;
    color: $brand1;
    padding-bottom: 1em;
    justify-content: space-between;

    nav {
      text-transform: uppercase;
      font-size: 18px;
      align-self: flex-end;
      flex: 1;
      display: flex;
      flex-flow: wrap;
      padding: 0 0.8rem;
      justify-content: flex-end;
      @media (max-width: 600px ) {
        display:none;
      }

      a {
        margin-left: 2vw;
        color: inherit;
        text-decoration: none;
      }
    }

    h1 {
      color: #fff;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
    }

    img {
      margin: 1em 1em 0.5em 1em;
      max-width: 207px;

    }
  }

  .wrap {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }

  .wrap2 {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .panel-content {
    padding: 2rem 1rem 3rem;
    background-color: hsla(255,100%,100%,.9);
  }

  .entry-content {
    // max-width:600px;
    margin-left: 8vw;
    // column-width: 300px;
    // column-count: 2;
    // column-gap: 4em;
  }

  p {
    margin-top: 0;
  }

  a.topnav {
    position: fixed;
    right: calc(30px - 17px) ;
    bottom:2vh;
    @include gold-border;
    background:#fff url(/css/images/backtotop.png) center  no-repeat;
    border-radius: 20px;
    width:34px;
    height:34px;

    z-index:40;
    color:transparent;
    overflow: hidden;
    text-indent: -9999px;
  }

  .mainnav {
    @include gold-border;
    position: fixed;
    right: calc(30px - 25px) ;
    top: calc( 50% - 25px );
    background:#fff url(/css/images/menu.png) center  no-repeat;
    border-radius: 30px;
    width:50px;
    height:50px;
    z-index:30;
    cursor: pointer;
    &.AtysToggle-on{
      background-image: url(/css/images/x.png);
      nav{
        display:block;
      }
    }
    nav {

      // border: 2px solid $brand3;

      display:none;
      position: fixed;
      top:calc(50% - 61px);
      right:calc(25px);
      width:215px;
      padding:1.2em 3em 1.2em 1em;
      background:transparent url(/css/images/menufield.svg) no-repeat;

      color:$brand1;
      a {
        text-transform: uppercase;
        display: block;
        color: inherit;
        text-decoration: none;
      }
    }
  }

  header h2 {
    padding: 1rem 0 .5rem;
    margin-bottom:1em;
    display: inline-block;
    color: #263546;
    font-size:30px;
    letter-spacing: 0.1em;
    width: 100%;
    max-width: 540px;
    background: url("/css/images/line.png") left bottom no-repeat;
    background-size: contain;
  }

  .panel-image {
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 50%;
    padding-top: 60vw;
    #panel3 & {

      @media (max-width: 550px ) {
        background-attachment: scroll;
      }
    }

  }

  .panel-image-prop {
    // padding-top:60%;
  }

  input,
  textarea {
    @include gold-border;
    padding: 0.5em 0.75em;
    font-size: inherit;
    color: inherit;

    margin-top: 0.875em;
    width: 100%;
    font-family: inherit;
    &:focus{
      outline:none;
      border-color:#a08b20;


    }
    &::placeholder{
      font-family: inherit;
    }
  }

  .panel-contact {
    header {}

    address {
      font-style: normal;
      margin-top: 2em;
      // font-size: inherit;

      div {
        max-width: 320px;
        column-width: 140px;
        column-count: 2;
        column-gap: 1em;
      }

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .btn {
    @include gold-border;
    padding: 0.5em 0.75em;
    margin-top: 0.875em;
    font-size: 1rem;

    color: $brand2;
    font-family: inherit;
  }

  .location {
    padding-bottom: 0;
  }

  .location .entry-location {
    height: 52.5vw;
    max-height: 526px;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;

    .location_markers li {
      position: absolute;
      display: block;
      width: 20px;
      height: 30px;

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        margin-left: -1.5vw;
        margin-top: -4.2vw;
        background-image: url("/css/images/pointer.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left bottom;
        width: 3vw;
        height: 4.2vw;
        z-index:12;
        @media (min-width: 1034px ) {
          width: 30px;
          height: 42px;
          margin-left: -15px;
          margin-top: -42px;
        }
      }
      div{
        position: absolute;
        display: none;
        padding:0 .5em;
        margin-top: calc(-1.3em - 2.1vmax);
        @include gold-border;

        text-transform: capitalize;
        color:$brand1;
        z-index:11;


      }
      &:hover div,
      &:target div
      {
        display:block;
      }
    }
  }

  article {
    position: relative;
  }

  .star {
position: relative;

  }
  .star::before {
    content: " ";
    background-image: url("/css/images/ster.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 12vw;
    min-width: 80px;
    min-height: 80px;
    height: 12vw;
    z-index: 10;
    right: 5vw;
    top: -6vw;
  }

  .star-left::before {
    left: 5vw;
    right: auto;
  }

  .star-bottom::before {
    top: auto;
    bottom: -6vw;
  }

  footer {
    padding: .6em 1em .6em 1em;
    color: $brand1;
    font-size: 16px;
    div{
      display:flex;
      flex-flow:row wrap;
      span {
        padding-right:.4em;
      }
    }

    a {
      text-decoration: none;
      color: $brand3;
    }
  }
