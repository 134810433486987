html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font: 18px/1.5 'Philosopher', sans-serif;
  color: #838e9c;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  color: #294153;
  text-transform: uppercase;
}

h1 {
  font-size: 27px;
}

body > header {
  margin: 0 2em;
  min-height: 120px;
  display: flex;
  flex-flow: wrap;
  align-items: flex-end;
  color: #294153;
  padding-bottom: 1em;
  justify-content: space-between;
}

body > header nav {
  text-transform: uppercase;
  font-size: 18px;
  align-self: flex-end;
  flex: 1;
  display: flex;
  flex-flow: wrap;
  padding: 0 0.8rem;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  body > header nav {
    display: none;
  }
}

body > header nav a {
  margin-left: 2vw;
  color: inherit;
  text-decoration: none;
}

body > header h1 {
  color: #fff;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

body > header img {
  margin: 1em 1em 0.5em 1em;
  max-width: 207px;
}

.wrap {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.wrap2 {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.panel-content {
  padding: 2rem 1rem 3rem;
  background-color: rgba(255, 255, 255, 0.9);
}

.entry-content {
  margin-left: 8vw;
}

p {
  margin-top: 0;
}

a.topnav {
  position: fixed;
  right: calc(30px - 17px);
  bottom: 2vh;
  border: 2px solid #d6b13a;
  background-color: #fff;
  border-radius: 10px;
  background: #fff url(/css/images/backtotop.png) center no-repeat;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  z-index: 40;
  color: transparent;
  overflow: hidden;
  text-indent: -9999px;
}

.mainnav {
  border: 2px solid #d6b13a;
  background-color: #fff;
  border-radius: 10px;
  position: fixed;
  right: calc(30px - 25px);
  top: calc( 50% - 25px);
  background: #fff url(/css/images/menu.png) center no-repeat;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  z-index: 30;
  cursor: pointer;
}

.mainnav.AtysToggle-on {
  background-image: url(/css/images/x.png);
}

.mainnav.AtysToggle-on nav {
  display: block;
}

.mainnav nav {
  display: none;
  position: fixed;
  top: calc(50% - 61px);
  right: calc(25px);
  width: 215px;
  padding: 1.2em 3em 1.2em 1em;
  background: transparent url(/css/images/menufield.svg) no-repeat;
  color: #294153;
}

.mainnav nav a {
  text-transform: uppercase;
  display: block;
  color: inherit;
  text-decoration: none;
}

header h2 {
  padding: 1rem 0 .5rem;
  margin-bottom: 1em;
  display: inline-block;
  color: #263546;
  font-size: 30px;
  letter-spacing: 0.1em;
  width: 100%;
  max-width: 540px;
  background: url("/css/images/line.png") left bottom no-repeat;
  background-size: contain;
}

.panel-image {
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 60vw;
}

@media (max-width: 550px) {
  #panel3 .panel-image {
    background-attachment: scroll;
  }
}

input,
textarea {
  border: 2px solid #d6b13a;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5em 0.75em;
  font-size: inherit;
  color: inherit;
  margin-top: 0.875em;
  width: 100%;
  font-family: inherit;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #a08b20;
}

input::placeholder,
textarea::placeholder {
  font-family: inherit;
}

.panel-contact address {
  font-style: normal;
  margin-top: 2em;
}

.panel-contact address div {
  max-width: 320px;
  column-width: 140px;
  column-count: 2;
  column-gap: 1em;
}

.panel-contact address h3 {
  margin-bottom: 0;
}

.btn {
  border: 2px solid #d6b13a;
  background-color: #fff;
  border-radius: 10px;
  padding: 0.5em 0.75em;
  margin-top: 0.875em;
  font-size: 1rem;
  color: #838e9c;
  font-family: inherit;
}

.location {
  padding-bottom: 0;
}

.location .entry-location {
  height: 52.5vw;
  max-height: 526px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.location .entry-location .location_markers li {
  position: absolute;
  display: block;
  width: 20px;
  height: 30px;
}

.location .entry-location .location_markers li::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -1.5vw;
  margin-top: -4.2vw;
  background-image: url("/css/images/pointer.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  width: 3vw;
  height: 4.2vw;
  z-index: 12;
}

@media (min-width: 1034px) {
  .location .entry-location .location_markers li::before {
    width: 30px;
    height: 42px;
    margin-left: -15px;
    margin-top: -42px;
  }
}

.location .entry-location .location_markers li div {
  position: absolute;
  display: none;
  padding: 0 .5em;
  margin-top: calc(-1.3em - 2.1vmax);
  border: 2px solid #d6b13a;
  background-color: #fff;
  border-radius: 10px;
  text-transform: capitalize;
  color: #294153;
  z-index: 11;
}

.location .entry-location .location_markers li:hover div,
.location .entry-location .location_markers li:target div {
  display: block;
}

article {
  position: relative;
}

.star {
  position: relative;
}

.star::before {
  content: " ";
  background-image: url("/css/images/ster.png");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 12vw;
  min-width: 80px;
  min-height: 80px;
  height: 12vw;
  z-index: 10;
  right: 5vw;
  top: -6vw;
}

.star-left::before {
  left: 5vw;
  right: auto;
}

.star-bottom::before {
  top: auto;
  bottom: -6vw;
}

footer {
  padding: .6em 1em .6em 1em;
  color: #294153;
  font-size: 16px;
}

footer div {
  display: flex;
  flex-flow: row wrap;
}

footer div span {
  padding-right: .4em;
}

footer a {
  text-decoration: none;
  color: #d6b13a;
}
